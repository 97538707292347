import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { ImageDialog } from '../../product/product';
import { UtilsService } from '../utils.service';
import { SafeWindowPipe } from '../safe-window.pipe';
import { MatButtonModule } from '@angular/material/button';
import { transparentDialog } from '../constants';

@Component({
  selector: 'app-image-dialog',
  standalone: true,
  imports: [MatDialogContent, MatDialogActions, SafeWindowPipe, MatButtonModule, MatDialogClose],
  templateUrl: './image-dialog.component.html',
  styleUrl: './image-dialog.component.scss'
})
export class ImageDialogComponent implements OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<ImageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public imageUrl: ImageDialog,
    private utils: UtilsService
  ) {
    document.body.classList.add(transparentDialog);
  }

  close(): void {
    this.dialogRef.close();
  }

  copyImage(): void {
    this.utils.copyClipboard(this.imageUrl.copyLink || this.imageUrl.url);
  }

  ngOnDestroy() {
    document.body.classList.remove(transparentDialog);
  }
}
