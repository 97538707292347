export class CustomRequestOptions {
    ignoreCache: boolean = true;
    showLoading: boolean = true;
    requestOptions: any;
    onProgress?: Function;
    reportProgress: boolean = false;
    observe?: string;
}

export interface ListResponse {
    items: Array<any>;
    count: number;
}

export interface ListRowsResponse {
    rows: Array<any>;
    count: number;
}