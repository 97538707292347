export enum Environment {
    PRODUCTION = 1,
    QA = 2,
    DEV = 3,
}

export enum EndPoints {
    CMS = 'cms',
    GRAPH = 'graph',
    CAROUSEL = 'carousel',
    NOTIF_WEBSOCKETS = 'notificationsWebsocket',
    WEBSOCKETS = 'websocket',
    ANALYTICS = 'analytics',
    UPLOAD = 'upload',
    THREE_JS_VIEWER = 'threejsviewer',
    CREATORS_VIEWER = 'creatorsviewer',
    DOWNLOAD = 'download',
    CREATORS = 'creators',
    PLAYGROUND = 'playground',
    COLLECTOR = 'collector',
  }
  