export const CORS_TOKEN = 'NgQun95Vma7QVVc34Lqxw7ETNWwmnuBA';
export const PREVIEW_FALLBACK = '/assets/images/asset.svg';
export const StrongPasswordRegx: RegExp =
  /^(?=\S*[a-z])(?=\S*[A-Z])(?=\S*\d)(?=\S*[^\w\s])\S{8,}$/;
export const PlatformName = 'HexaGen';
export const themeColor = '#3f51b5';
export const themeSeconderyColor = '#A33276';
export const imageCardDelay = 15;
export const noPaddDialog = 'no-padd-dialog';
export const transparentDialog = 'transparent-dialog';
export const defaultMonthlyCredits = 200;
export const mobileThreshold = 1024;
export const allowUnAuthPaths = [
  '/retailer/user/password',
  '/register',
  '/terms',
  '/privacy',
  '/convertor',
  '/login',
  '/signup',
  '/reset',
  '/update',
  '/home',
  '/',
  '/pricing',
  '/auth/sso',
  '/preview',
];
