import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private _cache = {} as { [id: string]: string };
  constructor() {
    let serverCache = this._cache;
    this.rehydrate(serverCache);
  }
  has(key: string): boolean {
    return this._cache.hasOwnProperty(key);
  }
  set(key: string, value: any): void {
    this._cache[key] = value;
  }
  get(key: string): any {
    return this._cache[key];
  }
  clear(): void {
    Object.keys(this._cache).forEach((key) => {
      delete this._cache[key];
    });
  }
  dehydrate() {
    let json = {} as { [id: string]: string };
    Object.keys(this._cache).forEach((key: string) => {
      json[key] = this._cache[key];
    });
    return json;
  }
  rehydrate(json: { [id: string]: string }) {
    Object.keys(json).forEach((key: string) => {
      this._cache[key] = json[key];
    });
  }

  toJSON() {
    return this.dehydrate();
  }
}
