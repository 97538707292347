import * as i0 from '@angular/core';
import { Injectable } from '@angular/core';
import { Subject, filter, map } from 'rxjs';
class BroadcasterService {
  _eventBus;
  constructor() {
    this._eventBus = new Subject();
  }
  broadcast(key, data) {
    this._eventBus.next({
      key,
      data
    });
  }
  on(key) {
    return this._eventBus.asObservable().pipe(filter(event => event.key === key)).pipe(map(event => event.data));
  }
  static ɵfac = function BroadcasterService_Factory(t) {
    return new (t || BroadcasterService)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: BroadcasterService,
    factory: BroadcasterService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BroadcasterService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();

/*
 * Public API Surface of ng-broadcaster
 */

/**
 * Generated bundle index. Do not edit.
 */

export { BroadcasterService };
