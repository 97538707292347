export interface ScriptsFetcher {
    loaded: boolean;
    promise: Array<Function>;
    src: string;
    element?: HTMLScriptElement|HTMLStyleElement;
}

export enum ScriptsFetcherType {
    SCRIPT = 1,
    STYLE = 2,
}

export interface ElementDimensions {
    width?: number;
    height?: number;
}

export enum DimensionsUnits {
    INCH = 1,
    CM = 2,
}

export interface IHasDates {
    created_at: Date;
    updated_at: Date;
}

export interface IPageFilter {
    limit: number;
    offset: number;
    is_desc: boolean;
    order_by: string;
    session_id: string;
}

export interface FileObject {
    base64: string;
    name: string;
    suffix: string;
    file: File;
    naturalWidth: number,
    naturalHeight: number,
    additionalData?: any;
}

export interface FileUploadRequest {
    filename: string;
    file: string;
    width?: number;
    height?:number;
    rotate?: string; //"horizontal" / "vertical" <- Optional, for images only. 
    compress?: boolean;
}

export class ShareObject {
    constructor() {
        this.title = 'HexaGen';
        this.text = 'Check out this 3D model';
        this.url = window.location.href;
    }
    title: string;
    text: string;
    url: string;
}
