import { Injectable } from '@angular/core';
import { PixelArtistsEvents, StatisticsPixel } from './pixels';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { EndpointsService } from '../communication/endpoints.service';
import { BodyService } from './body.service';
import { StorageService } from 'ng-storage-service';
import { BroadcasterService } from 'ng-broadcaster';
import { UtilsService } from './utils.service';
import { skip } from 'rxjs';

// declare var ga: any;
declare var gtag: any;

@Injectable({
  providedIn: 'root'
})
export class PixelsService {
  private _sessionId: string;
  private firstGA: boolean;
  // private clickId: number;
  private pageViewId: number;
  private _ignorePageViewId: boolean;
  constructor(
    private utils: UtilsService,
    private auth: AuthService,
    private endpoints: EndpointsService,
    private body: BodyService,
    private storage: StorageService,
    private router: Router,
    private broadcaster: BroadcasterService
  ) {
    // this.clickId = 0;
    this.pageViewId = 0;
    this.firstGA = true;
    this.refreshSession();
    let prevPage = window.location.pathname.replace('/', '');
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd && prevPage !== evt.url.replace('/', '')) {
        prevPage = evt.url.replace('/', '');
        this.incrementPageViewId(evt);
      }
    });
    this.broadcaster.on('sendPixel').subscribe((this as any).sendPixel.bind(this));
    this.broadcaster.on('refreshSession').pipe(skip(1)).subscribe(this.refreshSession.bind(this));
    // setTimeout(() => {
    //   try {
    //     if (clarity && this.auth.isloggedIn()) {
    //       // clarity(&quot;identify&quot;, <customuserid>,<customsessionid>,<custompageid>)
    //       // clarity('identify', this.auth.user.id);
    //       clarity('set', 'id', this.auth.user.id);
    //     }
    //   } catch (e) {
    //     console.warn('clarity send event failure', e);
    //   }
    // }, 1000);
  }

  private incrementPageViewId(evt: any) {
    if (!this._ignorePageViewId)
      this.pageViewId = evt.id;
  }

  get sessionId(): string {
    return this._sessionId;
  }

  get ignorePageViewId() {
    return this._ignorePageViewId;
  }

  set ignorePageViewId(value: boolean) {
    this._ignorePageViewId = value;
  }

  refreshSession() {
    this._sessionId = this.utils.generateUUID();
  }

  getCurrentPageType(): string {
    let page, pageParams, params = '', sections = this.router.url.split('/');
    page = pageParams = sections[1];
    if (pageParams.indexOf(';') > -1) {
      page = pageParams.substring(0, pageParams.indexOf(';'));
      params = this.router.url.substring(this.router.url.indexOf(';'), this.router.url.length);
    }
    switch (page) {
      // case 'offer': {
      //   return page;
      // }
      // case 'offers': {
      //   return page;
      // }
      // case 'uploads': {
      //   return page;
      // }
      // case 'jobs': {
      //   if (sections[2] === 'job')
      //     return sections[2];
      //   if (sections[2] === 'feedbacks')
      //     return 'job_feedback';
      // }
      default: {
        return pageParams;
      }
    }
  }

  sendPixel(pixelArtistsEvents: PixelArtistsEvents) {
    let client_id = this.utils.getClientId();
    // pixelArtistsEvents.click_id = this.clickId++;
    pixelArtistsEvents.user_agent = navigator.userAgent;
    pixelArtistsEvents.client_time = new Date().getTime();
    pixelArtistsEvents.type = 'Playground';
    pixelArtistsEvents.client_id = client_id;
    const gclid = this.utils.getUrlParam(window.location.href, 'gclid');
    if (gclid)
      pixelArtistsEvents.gclid = gclid;
    if (!pixelArtistsEvents.url)
      pixelArtistsEvents.url = window.location.href;
    pixelArtistsEvents.pageview_id = `${this._sessionId}_${this.pageViewId}`;
    if (!pixelArtistsEvents.page_type)
      pixelArtistsEvents.page_type = this.getCurrentPageType();
    // pixelArtistsEvents.expandMode = this.body.getExpandMode();
    if (pixelArtistsEvents.affiliate_id)
      this.storage.set('reference_id', pixelArtistsEvents.affiliate_id);
    else
      pixelArtistsEvents.affiliate_id = this.storage.get('reference_id');
    let pixel = {
      artists_events: pixelArtistsEvents
    } as StatisticsPixel;
    // pixel.artists_events.session_id = this._sessionId;
    // pixel.artists_events.referer = window.location.href;
    pixel.artists_events.referer = document.referrer;
    if(pixelArtistsEvents?.event?.toLowerCase() === 'pageview'){
      pixel.artists_events.screen_width = screen.width;
      pixel.artists_events.screen_height = screen.height;
    }
    if (this.auth.user) {
      pixel.artists_events.user_id = this.auth.user.id;
    }
    if (pixelArtistsEvents.event === "pageView"){
      pixel.artists_events.screen_height= window.screen.height;
      pixel.artists_events.screen_width= window.screen.width;
    }
    // const options = {
    //   showLoading: false
    // } as CustomRequestOptions;
    // this.rest.pixel('post', pixel);//, '', options);
    // if (showScreen) {
    //   this.utils.getCurrentScreenshot().subscribe((screenshot) => {
    //     let comments = JSON.parse(pixel.artists_events.comments);
    //     comments.screenshot = screenshot;
    //     pixel.artists_events.comments = JSON.stringify(comments);
    //     this.send(pixel);
    //   });
    // }
    // else
    this.send(pixel);
  }

  private send(pixel: StatisticsPixel) {
    let xhr = new XMLHttpRequest();
    xhr.open("POST", this.endpoints.getEndpointDomain('collector') + '/reports', true);
    xhr.setRequestHeader('Content-type', 'application/json');
    xhr.send(JSON.stringify(pixel));
    if (this.body.isProd) {
      this.gaSend(pixel);
      // if (pixel.artists_events.hotjar)
      //   this.hotjar('trigger', pixel.artists_events.event_type);
    }
    this.updateDataLayer(pixel);
  }

  private gaSend(pixel: StatisticsPixel) {
    try {
      if (this.firstGA) {
        // ga('create', 'G-2X6SXCDW0H', 'auto');
        this.firstGA = false;
      }
      // ga('send', {
      //   hitType: 'event',
      //   eventCategory: this.utils.ucFirst(this.utils.spaceCamelcase(pixel.artists_events.event_type)),
      //   eventAction: window.event ? window.event.type : null,
      //   eventLabel: pixel.artists_events.referer,
      //   eventValue: pixel.artists_events.event_value
      // });
      gtag('event', pixel.artists_events.event, {
        button_name: pixel.artists_events.button_name,
        click_type: pixel.artists_events.click_type,
        // event_category: this.utils.ucFirst(this.utils.spaceCamelcase(pixel.artists_events.event)),
        event_action: window.event ? window.event.type : null,
        event_referer: pixel.artists_events.referer,
        transaction_id: pixel.artists_events.transaction_id,
        value: pixel.artists_events.value,
        currency: pixel.artists_events.currency,
        tax: pixel.artists_events.tax,
        items: pixel.artists_events.items,
        affiliate_id: pixel.artists_events.affiliate_id
      });
      // ga('send', 'event', {
      //   eventCategory: this.utils.ucFirst(this.utils.spaceCamelcase(pixel.artists_events.event)),
      //   eventAction: window.event ? window.event.type : null,
      //   eventLabel: pixel.artists_events.referer,
      //   eventValue: pixel.artists_events.event_value
      // });
    } catch (e) {
      console.warn('ga send event failure', e);
    }
  }

  updateDataLayer(pixel: StatisticsPixel) {
    const windowAny = window as any;
    windowAny.dataLayer = windowAny.dataLayer || [];
    let e = {
      event: pixel.artists_events.event
    };
    Object.assign(e, pixel.artists_events);
    windowAny.dataLayer.push(e);
  }
}
