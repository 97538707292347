import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {
  private _numOfRequestsInProgress: number = 0;
  private _showSelfProfile: boolean;
  constructor() { }

  get showSelfProfile() {
    return this._showSelfProfile;
  }

  set showSelfProfile(value: boolean) {
    this._showSelfProfile = value;
  }

  get numOfRequestsInProgress() {
    return this._numOfRequestsInProgress;
  }

  public increaseNumOfRequestsInProgress() {
    setTimeout(() => { this._numOfRequestsInProgress++; });
  }

  public decreaseNumOfRequestsInProgress() {
    setTimeout(() => {
      this._numOfRequestsInProgress--;
      if (this._numOfRequestsInProgress < 0)
        this._numOfRequestsInProgress = 0;
    });
  }

  public resetNumOfRequestsInProgress() {
    setTimeout(() => {
      this._numOfRequestsInProgress = 0;
    });
  }
}
