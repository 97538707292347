import { Injectable } from '@angular/core';
import { ScriptsFetcher, ScriptsFetcherType, ShareObject } from './utils';
import {
  IFilesOptions,
  IScreenNotification,
  ScreenNotificationType,
} from './enums';
import { BroadcasterService } from 'ng-broadcaster';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from 'ng-storage-service';
import { IReconstructJob, IReconstructionAction } from '../generate/generate';
import { Router } from '@angular/router';

declare var Tiff: any;

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  private _scriptsFetcher: { [id: string]: ScriptsFetcher } = {};
  static TIFF_CONVERTER =
    'https://cdn.hexa3d.io/hotlink-ok/scripts/tiff.min.js';
  constructor(
    private broadcaster: BroadcasterService,
    private storage: StorageService,
    private router: Router
  ) {}

  async fetchCode(
    src: string,
    type: ScriptsFetcherType
  ): Promise<HTMLScriptElement | HTMLStyleElement | undefined> {
    return new Promise(async (resolve, reject) => {
      if (!this._scriptsFetcher[src]) {
        this._scriptsFetcher[src] = {
          loaded: false,
          promise: [resolve],
          src,
        };
        switch (type) {
          case ScriptsFetcherType.SCRIPT: {
            this._scriptsFetcher[src].element = await this.loadScript(src);
            break;
          }
          case ScriptsFetcherType.STYLE: {
            this._scriptsFetcher[src].element = await this.loadStylesheet(src);
            break;
          }
        }
        this._scriptsFetcher[src].loaded = true;
        this._scriptsFetcher[src].promise.forEach((f) =>
          f(this._scriptsFetcher[src].element)
        );
        return;
      }
      if (this._scriptsFetcher[src].loaded) {
        resolve(this._scriptsFetcher[src].element);
        return;
      }
      this._scriptsFetcher[src].promise.push(resolve);
    });
  }

  async loadScript(
    url: string,
    successCB?: Function
  ): Promise<HTMLScriptElement> {
    return new Promise((resolve: Function, reject: Function) => {
      let script = document.createElement('script');
      script.src = url;
      script.type = 'text/javascript';
      script.addEventListener(
        'load',
        () => {
          resolve(script);
          if (successCB) successCB(script);
        },
        false
      );
      document.getElementsByTagName('body')[0].appendChild(script);
    });
  }

  async loadStylesheet(url: string): Promise<HTMLStyleElement> {
    return new Promise((resolve: Function, reject: Function) => {
      var head = document.getElementsByTagName('head')[0];
      var link = document.createElement('link');
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = url;
      link.media = 'all';
      head.appendChild(link);
      var img = new Image();
      img.onerror = () => {
        resolve(link);
      };
      img.onload = () => {
        resolve(null);
      };
      img.src = url;
    });
  }

  public notifyUser(b: IScreenNotification) {
    this.broadcaster.broadcast('notifyUser', b);
  }

  public deepCopyByValue(src: any, onlyInextensible = false): any {
    try {
      if (onlyInextensible && Object.isExtensible(src)) return src;
      return JSON.parse(JSON.stringify(src));
    } catch (e) {
      return null;
    }
  }

  toUtcEpoch(et: string, isStartOfDay: boolean) {
    let date = new Date(et);
    if (isStartOfDay) date.setHours(0, 0, 0, 0);
    else date.setHours(23, 59, 59, 999);
    let utcDate = Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    );
    return utcDate;
  }

  getErrorMessage(err: HttpErrorResponse, fallbackMsg = '') {
    let msg = err && err.error ? err.error : fallbackMsg;
    if (msg && msg.error && msg.error.message) msg = msg.error.message;
    if (msg && msg.indexOf && msg.indexOf('Reason: ') == 0) {
      msg = msg.substring(8);
    }
    return msg;
  }

  httpErrorResponseHandler(
    err: HttpErrorResponse,
    fallbackMsg?: string,
    duration?: number
  ) {
    let data: IScreenNotification = {
      text: this.getErrorMessage(err, fallbackMsg),
      type: ScreenNotificationType.Error,
      action: 'OK',
      duration: duration,
    };
    this.broadcaster.broadcast('notifyUser', data);
  }

  injectIframe(src: string): Promise<HTMLIFrameElement> {
    return new Promise((resolve, reject) => {
      let iframe = document.querySelector(
        `iframe[src="${src}"]`
      ) as HTMLIFrameElement;
      if (!iframe) {
        iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
      }
      const handleLoad = () => {
        resolve(iframe);
      };
      iframe.addEventListener('load', handleLoad, true);
      iframe.src = src;
    });
  }

  async observableToPromise(o: Observable<any>): Promise<any> {
    return new Promise((resolve: Function, reject: Function) => {
      o.subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  isURL(txt: string) {
    if (!txt) return false;
    const urlPattern = new RegExp(
      '^(https?:\\/\\/)?' + // validate protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // validate fragment locator
    return !!urlPattern.test(txt);
  }

  public isAboveTS(present: number, past: number, maxMS: number) {
    return present - past > maxMS;
  }

  spaceCamelcase(str: string): string {
    if (!str) return str;
    let rex = /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g;
    let res = str.replace(rex, '$1$4 $2$3$5');
    return res;
  }

  ucFirst(str: string) {
    if (!str) return str;
    str = str.charAt(0).toUpperCase() + (str.length > 1 ? str.slice(1) : '');
    return str;
  }

  public getClientId() {
    let client_id = this.storage.get('client_id');
    if (!client_id) {
      client_id = this.generateUUID();
      this.storage.set('client_id', client_id);
    }
    return client_id;
  }

  public generateUUID() {
    var d = new Date().getTime();
    if (window.performance && typeof window.performance.now === 'function') {
      d += performance.now(); //use high-precision timer if available
    }
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  }

  public getURLSearchParamsFromObj(obj: any): URLSearchParams {
    var params = new URLSearchParams();
    for (let key in obj) params.append(key, obj[key]);
    return params;
  }

  public removeApostropheFromArrays(a: any) {
    Object.keys(a).forEach((i) => {
      if (a[i] instanceof Array && typeof a[i][0] === 'string')
        for (let j = 0; j < a[i].length; j++) {
          a[i][j] = a[i][j].replace(/"/g, '');
        }
    });
  }

  public equals(x: any, y: any) {
    if (x === y) return true;
    // if both x and y are null or undefined and exactly the same

    if (!(x instanceof Object) || !(y instanceof Object)) return false;
    // if they are not strictly equal, they both need to be Objects

    if (x.constructor !== y.constructor) return false;
    // they must have the exact same prototype chain, the closest we can do is
    // test there constructor.

    for (var p in x) {
      if (!x.hasOwnProperty(p)) continue;
      // other properties were tested using x.constructor === y.constructor

      if (!y.hasOwnProperty(p)) return false;
      // allows to compare x[ p ] and y[ p ] when set to undefined

      if (x[p] === y[p]) continue;
      // if they have the same strict value or identity then they are equal

      if (typeof x[p] !== 'object') return false;
      // Numbers, Strings, Functions, Booleans must be strictly equal

      if (!this.equals(x[p], y[p])) return false;
      // Objects and Arrays must be tested recursively
    }

    for (p in y) {
      if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false;
      // allows x[ p ] to be set to undefined
    }
    return true;
  }

  public parseToArr(val: any, returnDefault = false): Array<any> {
    if (val instanceof Array) return val;
    let res = [];
    if (typeof val === 'string' && val) {
      try {
        res = JSON.parse(val);
        if (!returnDefault && res.length == 0) res = null;
      } catch (e) {
        res = val.split(',');
      }
    }
    if (!(res instanceof Array)) {
      res = [res];
    } else if (!(res instanceof Array) && !(val instanceof Array)) {
      res = [val];
    }
    return res;
  }

  async setTimeout(ms?: number) {
    return new Promise((resolve: any, reject: any) => {
      setTimeout(() => {
        resolve();
      }, ms);
    });
  }

  public getUrlParam(url: string, name: string): string {
    let searchParams = this.getUrlParams(url);
    return searchParams ? searchParams.get(name) : null;
  }

  public getUrlParams(url: string): URLSearchParams {
    if (!url || url.indexOf('?') == -1) return null;
    let paramsString = url.substring(url.indexOf('?') + 1);
    return new URLSearchParams(paramsString);
  }

  public copyClipboard(str: string, notify = true): boolean {
    let container = document.createElement('input');
    container.className = 'copy-clipboard';
    container.style.position = 'fixed';
    container.style.top = '0';
    container.style.left = '0';
    container.style.opacity = '0.05';
    container.value = str;
    document.body.appendChild(container);

    container.select();

    try {
      var successful = document.execCommand('copy');
      if (notify) {
        let data: IScreenNotification = {
          text: 'copied to clipboard',
          type: ScreenNotificationType.Info,
          action: 'OK',
        };
        this.broadcaster.broadcast('notifyUser', data);
      } else {
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Copying text command was: "' + msg + '"');
      }
    } catch (err) {
      console.log('unable to copy clipboard');
    }
    document.body.removeChild(container);
    return successful;
  }

  preloadImage(src: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve(img);
      };
      img.onerror = () => {
        resolve(img);
      };
      img.src = src;
    });
  }

  public closest(elem: Element, selector: string): Element {
    if (selector.indexOf('.') == 0) {
      if (elem.classList.contains(selector.substring(1))) return elem;
    }
    if (selector.indexOf('#') == 0) {
      if (elem.getAttribute('id') == selector.substring(1)) return elem;
    }
    if (elem.tagName && elem.tagName.toLowerCase() == selector.toLowerCase())
      return elem;
    if (elem.parentElement) return this.closest(elem.parentElement, selector);
    return null;
  }

  share(msg: ShareObject = new ShareObject()) {
    return new Promise(async (resolve, reject) => {
      if (!!!navigator['share']) {
        reject();
        return;
      }
      if (!msg) msg = new ShareObject();
      navigator['share'](msg)
        .then(() => () => {
          resolve(null);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  isBase64(url: string) {
    return url.indexOf('data:') === 0;
  }

  multipleDownloads(urls: Array<string>, name: string) {
    let counter = 0;
    urls.forEach((url) => {
      if (this.isBase64(url)) {
        const link = document.createElement('a');
        link.setAttribute(
          'download',
          name ? (counter ? name + `_${counter}` : name) : ''
        );
        link.href = url;
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else window.location.assign(url);
    });
  }

  getSrcSuffix(src: string, suffixToAdd: string, changeCDN: boolean) {
    if (suffixToAdd) {
      if (src.indexOf('?') > -1 && suffixToAdd.indexOf('?') === 0)
        src += `&${suffixToAdd.substring(1)}`;
      else src += suffixToAdd;
    }
    if (changeCDN)
      // src = src.replace('//cdn.hexa3d.io/', '//img-cdn.azureedge.net/');
      src = src.replace('//cdn.hexa3d.io/', '//himg-cdn.com/');
    return src;
  }

  getPreview(item: IReconstructJob, width?: number, height?: number): string {
    return this.isURL(item.preview)
      ? this.getSrcSuffix(
          item.preview,
          width && height ? `?w=${width}&h=${height}` : null,
          true
        ).toString()
      : null;
  }

  public setUrlParam(url: string, name: string, value: string): string {
    // if (!url || url.indexOf('?') == -1) return url;
    if (!url) return url;
    let paramsString = url.substring(url.indexOf('?') + 1);
    if (url.indexOf('?') == -1) paramsString = '';
    let searchParams = new URLSearchParams(paramsString);
    // if (isNaN(parseInt(value)))
    //   value = null;
    if (typeof value === 'number' || typeof value === 'string')
      searchParams.set(name, value);
    else searchParams.delete(name);
    if (paramsString)
      return url.substring(0, url.indexOf('?') + 1) + searchParams.toString();
    if (searchParams.toString()) return `${url}?${searchParams.toString()}`;
    return url;
  }

  public getDomain(url: string): string {
    let hostname: string;
    if (url.indexOf('//') > -1) hostname = url.split('/')[2];
    else hostname = url.split('/')[0];
    hostname = hostname.split(':')[0];
    hostname = hostname.split('?')[0];
    return hostname;
  }

  getFiles(options: IFilesOptions): Promise<Array<File>> {
    return new Promise((resolve, reject) => {
      const input = document.createElement('input');
      input.type = 'file';
      input.multiple = options.multiple;
      input.accept = options.accept;
      input.addEventListener('change', () => {
        resolve(input.files as any);
      });
      input.addEventListener('cancel', () => {
        resolve([]);
      });
      input.click();
    });
  }

  async getFileSizeByURL(url: string): Promise<number> {
    const response = await fetch(url);
    return parseInt(response.headers.get('Content-Length')) / 1024 / 1024;
  }

  public getSafeDate(date: any): Date {
    if (date instanceof Date) return date;
    if (typeof date === 'string') {
      return new Date(date);
    }
    return null;
  }

  getTimespan(date1: Date, date2: Date): number {
    date1 = this.getSafeDate(date1);
    date2 = this.getSafeDate(date2);
    let diff = date2.getTime() - date1.getTime();
    return diff;
  }

  calcActionPercentage(item: IReconstructJob, action: IReconstructionAction) {
    const secondsInProgress =
      this.getTimespan(item.updated_at, new Date()) / 1000;
    return Math.max(
      Math.min(
        100,
        Math.floor(
          (secondsInProgress / (action.estimated_duration || 60)) * 100
        )
      ),
      0
    );
  }

  cancelEvent(e?: Event): boolean {
    if (!e) e = window.event;
    try {
      e.preventDefault();
    } catch (e) {}
    try {
      e.stopPropagation();
    } catch (e) {}
    try {
      e.stopImmediatePropagation();
    } catch (e) {}
    return false;
  }

  private async onFilerender(
    file: any,
    reader?: any,
    second = false
  ): Promise<string> {
    return new Promise(async (resolve, reject) => {
      let suffix = file.name.substring(file.name.lastIndexOf('.') + 1);
      if (
        !second &&
        (suffix.toLowerCase() == 'tif' || suffix.toLowerCase() == 'tiff')
      ) {
        let selfAny = self as any;
        if (!selfAny.Tiff) await this.loadScript(UtilsService.TIFF_CONVERTER);
        let xhr = new XMLHttpRequest();
        xhr.open('GET', reader.result);
        xhr.responseType = 'arraybuffer';
        xhr.onload = async (e) => {
          let buffer = xhr.response;
          let tiff = new Tiff({ buffer: buffer });
          let canvas = tiff.toCanvas();
          canvas.width = tiff.width();
          canvas.height = tiff.height();
          if (canvas) {
            let dataURI = tiff.toDataURL();
            var byteString = atob(dataURI.split(',')[1]);
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
            var ab = new ArrayBuffer(byteString.length);
            var ia = new Uint8Array(ab);
            for (var i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
            }
            var blob = new Blob([ab], { type: mimeString });
            let blobAny = blob as any;
            blobAny.lastModifiedDate = new Date();
            blobAny.name = file.name.replace(`.${suffix}`, '.png');
            let reader2 = new FileReader();
            reader2.addEventListener(
              'load',
              (arg) => {
                this.onFilerender(blob, reader2, true);
              },
              false
            );
            reader2.readAsDataURL(blob);
          } else this.onFilerender(file, reader, true);
        };
        xhr.send();
        return;
      }
      let img = new Image();
      let onFinish = () => {
        resolve(reader ? reader.result : null);
      };
      img.onload = onFinish;
      img.onerror = onFinish;
      img.src = reader.result;
    });
  }

  getBase64FromFile(file: any): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        async (arg) => {
          resolve(await this.onFilerender(file, reader));
        },
        false
      );
      reader.readAsDataURL(file);
    });
  }

  public forceRedirectTo(url: string, extras?: any) {
    this.router
      .navigateByUrl('/blank', { skipLocationChange: true })
      .then(() => this.router.navigate([url, extras]));
  }

  getAngularUrlParams() {
    let params = location.pathname.split(';')[1] as any;
    if (params) {
      params = params.split('=');
      const obj = {} as any;
      for (let i = 0; i < params.length; i += 2) {
        obj[params[i]] = params[i + 1];
      }
      // this.getURLSearchParamsFromObj(obj);
      return obj;
    }
  }

  merge2Images(base641: string, base642: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      const img1 = new Image();
      const img2 = new Image();
      img1.crossOrigin = 'anonymous';
      img2.crossOrigin = 'anonymous';
      img1.onload = () => {
        img2.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = Math.max(img1.width, img2.width);
          canvas.height = Math.max(img1.height, img2.height);
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img1, 0, 0);
          ctx.drawImage(img2, 0, 0);
          resolve(canvas.toDataURL());
        };
        img2.src = base642;
      };
      img1.src = base641;
    });
  }

  isDocHidden(): boolean {
    if (typeof document.visibilityState === 'string')
      return document.visibilityState === 'hidden';
    // always false (or not changing back to true) on firefox
    // if (typeof document.hidden === 'boolean')
    //     return document.hidden;
    return false;
  }

  // convertFileToBlob(file: File): Promise<Blob> {
  //   return new Promise(async (resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.addEventListener('load', (event: any) => {
  //       resolve(new Blob([event.target.result]));
  //     });
  //     reader.readAsArrayBuffer(file);
  //   });
  // }

  async isHDR(imgUrl: string): Promise<boolean> {
    const tester = 'Item:Semantic="GainMap"';
    try {
      const res = await fetch(imgUrl);
      const s = await res.text();
      return s.indexOf(tester) > -1;
    } catch (e) {
      return false;
    }
  }

  throw(msg: string): never {
    throw new Error(msg);
  }

  public sameDay(d1: Date, d2: Date) {
    if (typeof d1 === 'string')
      d1 = new Date(d1);
    if (typeof d2 === 'string')
      d2 = new Date(d2);
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
  }

  public fixImages(html: string): string {
    try {
      let div = document.createElement('div');
      div.innerHTML = html;
      return div.innerHTML.replace(/"\\&quot;/g, "'").replace(/\\&quot;"/g, "'");
    }
    catch (e) {
      return html;
    }
  }

  public stripScripts(s:string) {
    var div = document.createElement('div');
    div.innerHTML = s;
    var scripts = div.getElementsByTagName('script');
    var i = scripts.length;
    while (i--) {
      scripts[i].parentNode.removeChild(scripts[i]);
    }
    return div.innerHTML;
  }

  public getTextContent(html: string) {
    var div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent;
  }
}
