export const environment = {
  production: false,
  // facebookData: {
  //   client_id: '1348246012236286',
  //   redirect_uri: 'https://a.c3ddevelopment.com/artist/user/auth/facebook/sso',
  //   state: 'https://www.c3ddevelopment.com',
  // },
  googleData: {
    client_id:
      '41627611898-vdjf71vm5ivteghvscmb3hkfoe99k2c1.apps.googleusercontent.com',
    redirect_uri: 'https://r.h3dqa.com/retailer/session/auth/google/sso',
    state: 'https://gen.h3dqa.com'
  },
  endPoints: {
    c3dDomain: 'c3dqa.com',
    cmsDomain: 'h3dqa.com',
    viewerDomain: 'vqa.hexa3d.io',
    creatorsViewerDomain: 'vqa.creators3d.com',
  },
  paddle: {
    environment: 'sandbox',
    token: 'test_8fae2c79c96206e154ed671c5c1',
  },
  rootCssClass: 'qa',
  environmentName: 'QA',
  domain: 'https://gen.h3dqa.com/',
  captchaSiteKey: '6Le3L48eAAAAAKWvJnJu-cTV9h9szQmwLIWzYTwd',
  favIcon: 'favicon.qa.ico',
  authSrc: 'https://auth.hexa3d.io/hexa-auth.html&env=qa',
  viewerScript: 'https://vqa.hexa3d.io/hexa-viewer.bundle.js',
  enableSsoLogin: true,
  viewerServer: '2',
  csp: "default-src 'self' *.h3dqa.com; script-src 'self' *.h3dqa.com 'unsafe-eval' 'unsafe-inline' *.hexa3d.io *.google-analytics.com *.google.com *.gstatic.com *.googletagmanager.com; style-src 'self' *.h3dqa.com 'unsafe-inline' *.googleapis.com *.hexa3d.io; base-uri 'self' *.h3dqa.com; connect-src 'self' blob: data: *.h3dqa.com *.hexa3d.io *.google-analytics.com *.azureedge.net *.himg-cdn.com *.gstatic.com *.googletagmanager.com *.sentry.io; font-src 'self' *.h3dqa.com *.hexa3d.io *.gstatic.com; frame-src 'self' *.h3dqa.com *.hexa3d.io *.google.com; img-src 'self' *.h3dqa.com https: data: *.hexa3d.io *.azureedge.net *.himg-cdn.com *.gstatic.com *.googletagmanager.com; manifest-src 'self' *.h3dqa.com; media-src 'self' *.h3dqa.com;  worker-src 'self' blob: *.hexa3d.io;",
};
